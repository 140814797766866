import React from "react"
import { useParams } from "react-router-dom"
import ParticlesBg from "../particles-bg-2.5.0/src/index.js"
import blogs from "../data/blog-posts.json"
import "./styles/Blog.css"

export const BlogPost = ({post}) => {
    const { tag } = useParams();
    const postdata = blogs.find((post) => post.tag === tag);

    const date = new Date(Date.parse(postdata.date) + 14400000 * 1.5); //correct for timezone
    const formattedDate = date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    return (
        <div className="main blog">
            <div className="blog-header">
                <h1>{postdata.title}</h1>
                <h2>{formattedDate}</h2>
            </div>
            {postdata.content.map((section) => {
                switch (section.type) {
                    case "text":
                        return (
                            <div className="blog-section">
                                <p>{section.value}</p>
                            </div>
                        );
                    case "header":
                        return (
                            <div className="blog-section">
                                <h3>{section.value}</h3>
                            </div>
                        );
                    case "list":
                        return (
                            <div className="blog-section">
                                <ul>
                                    {section.value.map((item) => {
                                        return (
                                            <li>{item}</li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    case "image":
                        return (
                            <div className="blog-section">
                                <img src={section.value} />
                            </div>
                        );
                    case "video":
                        return (
                            <div className="blog-section">
                                <iframe src={section.value} width="540px" height="360px" title={section.alt} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        );
                    case "link":
                        return (
                            <div className="blog-section">
                                <a href={section.value} >{section.value}</a>
                            </div>
                        );
                    case "code":
                        return (
                            <div className="blog-section">
                                <pre>
                                    <code>{section.value}</code>
                                </pre>
                            </div>
                        );
                    default:
                        return (
                            <div className="blog-section">
                                <p>{section.value}</p>
                            </div>
                        );
                }
            })}
            <ParticlesBg type="cobweb" color="random" bg={true} num={(window.innerWidth/10)+5}/>
        </div>
    );
}