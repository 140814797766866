import React, {useState} from "react"
import {animated, useSpring} from "react-spring"
import "./styles/AppHeader.css"
const words = ["PYTHON", "PYTHON", 
    "HTML", "HTML", 
    "REACT", "REACT",
    "CSS", "CSS",
    "C++", "C++", 
    "C#", "C#", 
    "mySQL", 
    "AWS", 
    "AWS AMPLIFY",
    "S3", "EC2", "IAM", "AWS BEDROCK", "AWS Q",
    "CYBERSECURITY", "CYBERRESISTANCE",
    "UI/UX", 
    "UNITY", 
    "UNITY ENGINE", 
    "JAVASCRIPT", 
    "<>", "</>"];

const colors = [
    [255, 136, 136],
    [255, 204, 136],
    [255, 255, 136],
    [136, 255, 136],
    [136, 204, 255],
    [136, 136, 255],
    [204, 136, 255],
    [255, 136, 255]
]

const SlidingText = (text, size) => {
    var randomDelay = (Math.random() * (200000/window.screen.width)) - 40;
    var randomHeight = (Math.random() * 60)
    var randomSize = 1 + (Math.random() * .5) - .1;
    var randomColor = Math.floor(Math.random() * 8);
    var colorAlt = (randomColor+1)%8;

    var styles = {
        "animation-delay": randomDelay + "s",
        transform: "translateX(-100%)",
        marginTop: randomHeight + "px",
        "font-size": size + "em",
        "animation-duration": (10 * 1/size) + "s",
        "background-color": "rgba(" + (colors[randomColor][0]-136) + ", " + (colors[randomColor][1]-136) + ", " + (colors[randomColor][2]-136) + ", .5)",
        color: "rgba(" + colors[randomColor][0]/2 + ", " + colors[randomColor][1]/2 + ", " + colors[randomColor][2]/2 + ")",
    }
    
    return (
        <div className="slidediv" style={styles}>
            {text}
        </div>
    );
}

const SlidingList = () => {
    let shuffledWords = words.sort(() => Math.random() - .5);
    var size = .8;
    return shuffledWords.map((word) => {
        size += .035;
        return SlidingText(word, size);
    })
}

export const AppHeader = () => {
    SlidingList();
    return (
        <div className="headerdiv">
            <a href="/">Tyler Russell<em>.(me)</em></a>
        {SlidingList()}
        </div>        
    );
};