// #region imports
import React, { useState } from "react"
import { Document, Page, pdfjs } from 'react-pdf';
import ParticlesBg from "particles-bg"
import "./styles/Resume.css"
import aplus from "../img/comptialogos/aplus.png"
import netplus from "../img/comptialogos/netplus.png"
import secplus from "../img/comptialogos/secplus.png"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// #endregion
export const Resume = (props) => {
    var style = getComputedStyle(document.body);
    var color = style.getPropertyValue('--shade3');
    return (
        <div className="main">
            <div className="infomain">
                <a className="btn" href="/resume.pdf" target="_blank" rel="noreferrer">Open PDF</a>
            </div>
            <div className="document">
                <Document file="/resume.pdf">
                    <Page pageNumber={1}/>
                </Document>
                <div className="cert-imgs-resume">
                        <img src={aplus} alt="CompTIA A+ Certified" title="CompTIA A+ Certified"/>
                        <img src={netplus} alt="CompTIA Network+ Certified" title="CompTIA Network+ Certified"/>
                        <img src={secplus} alt="CompTIA Security+ Certified" title="CompTIA Security+ Certified"/>
                </div>
            </div>
            <ParticlesBg type="cobweb" bg={true} color={color} num={(window.innerWidth/10)+5} /> 
        </div>
    );
};