import React from 'react';
import "./styles/Footer.css";
const time = new Date().getFullYear();

export const Footer = (props) => {
    return (
        <footer>
            <div className="bar"/>
            <p><em>{time.toString()}</em> Tyler Russell</p>
        </footer>
    );
}