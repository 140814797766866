import React from "react"
import blogs from "../data/blog-posts.json"
import "./styles/Blog.css"
import ParticlesBg from "../particles-bg-2.5.0/src/index.js"

export const Blogs = (props) => {
    let orderedBlogs = blogs.sort((a, b) => { return Date.parse(b.date) - Date.parse(a.date) });
    return (
        <div className="main blog">
            <h1>Blogs</h1>
            {orderedBlogs.map((post) => {
                return (
                    <div className="digest">
                        <div className="blog-main-header">
                            <h3><a href={"/blog/" + post.tag}>{post.title}</a></h3>
                            <p>{post.date}</p>
                        </div>
                        <p>{post.content[0].value.slice(0, 350)} {post.content[0].value.length > 350 ? "..." : ""}</p>
                    </div>
                );
            })}
            <ParticlesBg type="cobweb" color="random" bg={true} num={(window.innerWidth/10)+5}/>
        </div>
    );
}