// #region imports
import React from "react"
import { Certs } from "./Certs"
import portrait from "../img/meforest2.png"
import ParticlesBg from "../particles-bg-2.5.0/src/index.js"
import blogposts from "../data/blog-posts.json"
// import ParticlesBg from "particles-bg"
import "./styles/Home.css"
// #endregion

export const Home = (props) => {
    var style = getComputedStyle(document.body);
    const color = style.getPropertyValue('--shade3');



    return (
        <div className="main">
            <div className="home-main">
                <div className="content">
                    <h1>Hi! I'm Tyler</h1>
                    <img src={portrait}/>
                </div>
            </div>
            <div className="home-desc">
                <h4>Student at <strong><a href="https://www.wgu.edu/" target="_blank" rel="noreferrer">Western Governor's University</a></strong></h4>
                <h4>Studying Cloud Computing</h4>
                <h4>Take a look at my <a href="/projects">projects</a>, <a href="/info">resume</a>, or <a href="/about">learn more about me</a>.</h4>
                <Certs divclass="extra"/>
            </div>
            <div className="recent">
                {BuildPosts(blogposts)}
            </div>
            <ParticlesBg type="cobweb" color="random" bg={true} num={(window.innerWidth/10)+5}/> 
        </div>
    );
};

function GetRecentPosts() {
    var recent = [];
    var allSorted = blogposts.sort((a, b) => { return Date.parse(b.date) - Date.parse(a.date) });
    recent.push(allSorted[0]);
    recent.push(allSorted[1]);
    return recent;
}

function BuildPosts(posts) {
    return (
    <div className="slideshow">
        {GetRecentPosts().map((post) => {
            return (
                <div className="recent-post">
                    <div className="titlebar">
                        <h3><a href={"/blog/" + post.tag}>{post.title}</a></h3>
                        <p>{post.date}</p>
                    </div>
                    <p>{post.content[0].value.slice(0, 250)} {post.content[0].value.length > 250 ? "..." : ""}</p>
                </div>
            );
        })}
    </div>
    );
}