// #region imports
import React from "react"
import IFrame from "react-iframe"

import insta from "../img/insta.png"
import linked from "../img/linked.png"
import github from "../img/github.png"
import email from "../img/email.png"
import me from "../img/me.png"

import ParticlesBg from "../particles-bg-2.5.0/src/index.js"

import "./styles/About.css"
// #endregion
export const About = (props) => {
    return (
        <div class="main">
            <div class="bar aboutbar"/>
            <div class="about-header">
                <h1>About Me:</h1>
                <div class="link-logos">
                    <a target="_blank" class="link-logo" href="https://www.instagram.com/tyler.hat/" rel="noreferrer"><img class="mx-auto d-block" src={insta} alt="Instagram Logo"/></a>
                    <a target="_blank" class="link-logo" href="https://www.linkedin.com/in/tyler-russell251/" rel="noreferrer"><img class="mx-auto d-block" src={linked} alt="LinkedIn Logo"/></a>
                    <a target="_blank" class="link-logo" href="https://github.com/Tyler-251" rel="noreferrer"><img class="mx-auto d-block" src={github} alt="GitHub Logo"/></a>
                    <a target="_blank" class="link-logo" href="mailto:tylerrussell@outlook.com" rel="noreferrer"><img class="mx-auto d-block" src={email} alt="Email Logo"/></a>
                </div>
            </div>
            <div class="bar aboutbar"/>
            <div class="about-main row">
                <div class="col-sm-12 col-lg-12 col-xl-6">
                    <img src={me} alt="A Picture of Me" class="meimg"/>
                </div>
                <div class="col-sm-12 col-lg-12 col-xl-6">
                    <p>
                        <pre/><strong class="text-lead">&nbsp; &nbsp; Hi!</strong> My name is Tyler Russell and I'm currently a student at Western Governors University pursuing a bachelor's in Cloud Computing. My current focus is centered around application-based projects that push my boundaries. I'm incredibly interested in delving into the science of the cloud and the ever-changing security scene. I have no way of knowing exactly where my career will take me, but my ambition is to one day make a substantial impact on the application development-end infrastructure that supports healthcare industries.  <br/>
                            <div class="p-space"/>
                        &nbsp; &nbsp; &nbsp; Most of my projects listed on this site are my game development projects. I've always had a love for music and art and tinkering with game dev is one of my favorite ways to express that. I began my studies at Missouri State University, where I learned the basics of computer science and found my interest in this hobby. After implementing net-code into a couple of my games and putting together this portfolio site, I found that I had a keen interest in networking. After some independent study, I happened upon WGU's program that matched what I was looking for in an education. 
                            <div class="p-space"/>
                        &nbsp; &nbsp; &nbsp; Besides my technical hobbies, anyone who knows me knows that I'm really into jazz. I had the opportunity to take music theory classes at Missouri State and ended up joining their top jazz program playing trumpet for a while. Since transferring, I've set down my mouthpiece and began learning piano, which has been a very fun challenge. I enjoy listening to it all, but some of my favorite artists are Dizzy Gillespie, Chick Corea, and Wayne Shorter. 

                    </p>
                        
                    <div class="song">
                        <h4>One of my favorite songs:</h4>
                        <IFrame
                            style="border-radius:12px"
                            src="https://open.spotify.com/embed/track/6xEHCWUvalb0fNYuAo591v?utm_source=generator&theme=0" 
                            width="100%" 
                            height="80" 
                            frameBorder="0" 
                            allowfullscreen="" 
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                            loading="lazy"
                            class="iframe"
                        />
                    </div>
                </div>  
            </div>
            <ParticlesBg type="cobweb" color="random" bg={true} num={(window.innerWidth/10)+5}/> 
        </div>
    );
};