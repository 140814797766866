import React from "react";
import Proton from "proton-engine";
import RAFManager, { start } from "raf-manager";
import Canvas from "./Canvas.jsx";
import { getColor } from "../utils/Color";

var GenerateSpectrum = (colorA, colorB, colorDivisions) => {
  let redA = `${colorA[1]}${colorA[2]}`
  let greenA = `${colorA[3]}${colorA[4]}`
  let blueA = `${colorA[5]}${colorA[6]}`
  let redB = `${colorB[1]}${colorB[2]}`
  let greenB = `${colorB[3]}${colorB[4]}`
  let blueB = `${colorB[5]}${colorB[6]}`

  redA = parseInt(redA, 16);
  blueA = parseInt(blueA, 16);
  greenA = parseInt(greenA, 16);
  redB = parseInt(redB, 16);
  blueB = parseInt(blueB, 16);
  greenB = parseInt(greenB, 16);

  let spectrum = [];
  for (let div=0; div < colorDivisions; div++) {
    let ratioA = (div+1) / (colorDivisions+1);
    let ratioB = (colorDivisions-1-div) / (colorDivisions+1);
    let newRed = (redA * ratioA) + (redB * ratioB);
    let newBlue = (blueA * ratioA) + (blueB * ratioB);
    let newGreen = (greenA * ratioA) + (greenB * ratioB);

    //saturate
    var highestValue = Math.max(newRed, newBlue, newGreen);
    var lowestValue = Math.min(newRed, newBlue, newGreen);
    var multiplier = 200/(highestValue-lowestValue);
  
    newRed = Math.floor((newRed-lowestValue+10) * multiplier);
    newBlue = Math.floor((newBlue-lowestValue+10) * multiplier);
    newGreen = Math.floor((newGreen-lowestValue+10) * multiplier);

    newRed = newRed.toString(16).padStart(2, "0").toUpperCase();
    newBlue = newBlue.toString(16).padStart(2, "0").toUpperCase();
    newGreen = newGreen.toString(16).padStart(2, "0").toUpperCase();
    spectrum.push(`#${newRed}${newBlue}${newGreen}`);
  }
  return spectrum;
}

export default class Cobweb extends React.Component {
  constructor(props) {
    super(props);
    this.renderProton = this.renderProton.bind(this);
    this.time = 0;
  }

  onCanvasInited(canvas, width, height) {
    this.createProton(canvas, width, height);
    RAFManager.add(this.renderProton);
  }

  componentWillUnmount() {
    try {
      RAFManager.remove(this.renderProton);
      this.proton.destroy();
    } catch (e) {}
  }

  onResize(width, height) {
    this.crossZoneBehaviour.zone.width = width;
    this.crossZoneBehaviour.zone.height = height;
    this.proton.renderers[0].resize(width, height);
  }

  createProton(canvas, width, height) {
    this.proton = new Proton();

    const emitter = new Proton.Emitter();
    emitter.rate = new Proton.Rate(
      this.props.num ? new Proton.Span(this.props.num) : new Proton.Span(100),
      new Proton.Span(0.05, 0.2)
    );

    emitter.addInitialize(new Proton.Mass(1));
    emitter.addInitialize(new Proton.Radius(3, 5));
    emitter.addInitialize(new Proton.Life(Infinity));

    const pointZone = new Proton.Position(
      new Proton.RectZone(0, 0, width, height)
    );
    emitter.addInitialize(pointZone);
    emitter.addInitialize(
      new Proton.Velocity(
        new Proton.Span(0.1, 0.3),
        new Proton.Span(-90, 90),
        "polar"
      )
    );

    emitter.addBehaviour(new Proton.Alpha(Proton.getSpan(0.2, 0.9)));
    emitter.addBehaviour(new Proton.Color(this.props.color || "#333"));
    this.crossZoneBehaviour = new Proton.CrossZone(
      new Proton.RectZone(0, 0, width, height),
      "cross"
    );
    emitter.addBehaviour(this.crossZoneBehaviour);

    emitter.emit("once");
    emitter.damping = 0;
    this.proton.addEmitter(emitter);
    this.proton.addRenderer(this.createRenderer(canvas, emitter));
  }

  createRenderer(canvas, emitter) {
    const context = canvas.getContext("2d");
    const renderer = new Proton.CanvasRenderer(canvas);
    const R = 140;

    renderer.onProtonUpdateAfter = ()=> {
      let particles = emitter.particles;

      for (let i = 0; i < particles.length; i++) {
        for (let j = i + 1; j < particles.length; j++) {
          let pA = particles[i];
          let pB = particles[j];
          let dis = pA.p.distanceTo(pB.p);

          if (dis < R) {
            let alpha = (1 - dis / R) * 0.5;
            let colorA = pA.color;
            let colorB = pB.color;
            
            let colorDivisions = 5;
            let spectrum = GenerateSpectrum(colorA, colorB, colorDivisions);

            for (let div = 0; div < colorDivisions; div++) {
              let progress = div / colorDivisions;
              let nextProgress = (div+1) / colorDivisions;

              
              //calculate start and end
              let startPX = pA.p.x + ((progress)*(pB.p.x - pA.p.x));
              let startPY = pA.p.y + ((progress)*(pB.p.y - pA.p.y));
              let endPX = pA.p.x + ((nextProgress)*(pB.p.x - pA.p.x));
              let endPY = pA.p.y + ((nextProgress)*(pB.p.y - pA.p.y));
              
              //calculate color
              var alphaModifier = (Math.sin(((startPY/100)) - (this.time - Math.abs(startPX-(window.innerWidth/2))/400)*5)+.3)/5; // makes a pulsing V of alpha
              let segmentColor = getColor(spectrum[div], alpha-alphaModifier);

              //draw 
              context.strokeStyle = segmentColor;
              context.beginPath();
              context.lineWidth = pA.radius/2;
              context.moveTo(startPX, startPY);
              context.lineTo(endPX, endPY);
              context.closePath();
              context.stroke();
            }
          }
        }
      }
    };

    return renderer;
  }

  renderProton() {
    this.proton && this.proton.update();
    this.time += .01;
  }

  render() {
    return (
      <Canvas bg={this.props.bg}
        globalCompositeOperation="darker"
        onCanvasInited={this.onCanvasInited.bind(this)}
        onResize={this.onResize.bind(this)}
      />
    );
  }
}
