import React from "react"

export const NavBar = () => {
    return (
        <nav className="navbar navbar-toggleable">
            <div className="container-fluid">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#myNavbar" />                       
                </div>
                <div className="collapse navbar-collapse show" id="myNavbar">
                    <div className="navbar nav">
                        <a rel="noreferrer" className="col-md-3 col-sm-6 text-center" href="/projects">Projects</a>
                        <a rel="noreferrer" className="col-md-3 col-sm-6 col-xs-12 text-center alt-nav" href="/blog">Blog</a>
                        <a rel="noreferrer" target="_blank" className="col-md-3 col-sm-6 text-center resumelink" href="/resume.pdf">Resume</a>
                        <a rel="noreferrer" className="col-md-3 col-sm-6 text-center alt-nav" href="/about">About</a>           
                    </div>
                </div>
            </div>
        </nav>
    );
};