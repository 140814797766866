import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { AppHeader } from "./components/AppHeader.js"
import { NavBar } from "./components/NavBar.js"
import { About } from "./components/About.js"
import { Resume } from "./components/Resume.js"
import { Home } from "./components/Home.js"
import { Projects } from "./components/Projects.js"
import { Footer } from "./components/Footer.js"
import { Error } from "./components/Error.js"
import { BlogPost } from "./components/BlogPost.js"
import { Blogs } from "./components/Blogs.js"
import NimbusShowcase from "./components/NimbusShowcase.js"
import "./index.css"

ReactDOM.render(
        <Router>
            <AppHeader/>
            <NavBar />
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/projects" element={<Projects/>}/>
                <Route path="/nimbus" element={<NimbusShowcase/>}/>
                <Route path="/resume" element={<Resume/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/blog/:tag"  element={<BlogPost/>}/>
                <Route path="/blog" element={<Blogs/>}/>
                <Route path="/*" element={<Error/>}/>
            </Routes>
            <Footer />
        </Router>
, document.getElementById("root"));

